<template>
 <div id="page-container" class="">
  <div class="content-wrap pb-5">
   <PbxDashboard
    :key="periodSelector"
    v-model:selector="periodSelector"
    :dataPbx="dataPbx"
    :dataElementsDuration="dataElementsDuration"
    @action="setCharts()"
    @button-selector="(...args) => getSelectedButton(...args)"
    :extensions="extensions"
    :dataSeriesStacked="dataSeriesStacked"
    :urlType="urlType"
    :dataSeriesConcatenated="dataSeriesConcatenated"
    :periodSelector="this.periodSelector"
    @timeSelector="setTimeSelected($event)"
    @all-selected="allSelected($event)"
    :buttonSelected="this.buttonSelected"
    :textMonth="this.textMonth"
    :timeSelected="this.timeSelected"
    @showWaitingDuration="setCharts($event)"
    :dataPbxDay="dataPbxDay"
    :elementLevelData="elementLevelData"
    :elementLevelDataRange="elementLevelDataRange"
    @waitingTimeRange="waitingTimeRange = $event"
    :waitingTimeRange="waitingTimeRange"
   />
   <div
    v-if="
     !Object.keys(dataPbx).length &&
     !dataElementsDuration.length &&
     !Object.keys(elementLevelDataRange).length &&
     elementLevelDataRange.labels &&
     !elementLevelDataRange.labels.length
    "
   >
    <EmptyStateDashboard />
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

//const buttonSelected = $t("dashboards.month");
export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   elementLevelData: {},
   elementLevelDataRange: {},
   dataPbx: {},
   dataPbxDay: {},
   dataPbxSeries: [],
   dataPbxLabels: [],
   dataElementsDuration: [],
   dataElementsDurationDay: [],
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataSeriesCurrentMonth: [],
   extensions: [],
   dataSeriesStacked: [],
   dataSeriesConcatenated: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: this.$t("dashboards.month"),
   textMonth: "",
   data: undefined,
   showWaitingDuration: true,
   waitingTimeRange: 20,
  };
 },
 methods: {
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getPbxData(stringOfElements, period, type) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${this.hostName}/durations?period=${period}`,
     {
      headers: {
       Authorizations: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    return res.data;
   } catch (error) {
    const status = error.response.status;
    this.$emit("setLoading", false);
    this.errorHandling(error);
    if (error.response.status === 500) {
     const msg = this.$t("error500");
     this.$router.push({
      name: "Error",
      query: { status, msg },
     });
    }
   }
   this.isLoading = false;
  },
  async setCharts(durationType) {
   this.$store.dispatch("pbxElementType", this.urlType);

   this.showWaitingDuration = durationType == true || durationType == false ? durationType : true;

   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   this.dataPbxSeries = [];
   this.dataPbxLabels = [];
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;
   let res = undefined;

   if (this.periodSelector) {
    period = this.periodSelector;
   }

   if (this.pbxElements && this.pbxElements.length > 0) {
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });
   }
   arrayOfElements = [...new Set(arrayOfElements)];

   stringOfElements = String(arrayOfElements[0]);

   for (let index = 1; index < arrayOfElements.length; index++) {
    stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
   }

   type = this.urlType;

   if (durationType == true || durationType == false) {
    res = this.data;
   } else {
    res = await this.getPbxData(stringOfElements, period, type);
    this.data = res;
   }

   let timeSelectedIsHour = false;
   let timeSelectedIsQuarter = false;

   if (this.timeSelected.length > 0) {
    if (this.timeSelected[0].slice(-2) === "00" && this.timeSelected[1].slice(-2) === "00") {
     timeSelectedIsHour = true;
    } else {
     timeSelectedIsQuarter = true;
    }
   }
   //    if (period.length == 8) {
   //     this.setPbxChartsDay(res);
   //     this.setElementsChartsDay(res);
   //    } else {
   //     this.setPbxCharts(res);
   //     this.setElementsCharts(res);
   //    }
   this.setPbxCharts(res);
   this.setElementsCharts(res);
   this.setElementLevelCharts(res);
   this.setElementLevelChartsWaitingRange(res);
  },
  setPbxCharts(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   keys = Object.keys(res);

   if (keys.length > 0) {
    this.dataPbxLabels = [];
    let objSeriesWaiting = {};
    let objSeriesTalking = {};
    objSeriesWaiting["data"] = [];
    objSeriesTalking["data"] = [];
    let total = 0;
    let totalWaiting = 0;
    let totalTalking = 0;
    keys.forEach((date, index) => {
     this.dataPbxLabels.push(date);
     let objKeys = Object.keys(res[date]);
     objSeriesWaiting["data"][index] ? "" : objSeriesWaiting["data"].splice(index, 0, 0);
     objSeriesTalking["data"][index] ? "" : objSeriesTalking["data"].splice(index, 0, 0);
     objKeys.forEach((key) => {
      let splitKey = key.split(":");
      if (splitKey[0] === "pbx") {
       if (splitKey[1] === dataType && dataType === "waiting") {
        objSeriesWaiting["name"] = "waiting";

        total = total + parseInt(res[date]["pbx:count"]);
        totalWaiting = totalWaiting + parseInt(res[date]["pbx:waiting"]);

        let value = parseInt(res[date]["pbx:waiting"]) / parseInt(res[date]["pbx:count"]);
        objSeriesWaiting["data"].splice(index, 1, value.toFixed(0));
       }
       if (splitKey[1] === dataType && dataType === "talking") {
        objSeriesTalking["name"] = "talking";

        let value = parseInt(res[date]["pbx:talking"]) / parseInt(res[date]["pbx:count"]);
        objSeriesTalking["data"].splice(index, 1, value.toFixed(0));
        total = total + parseInt(res[date]["pbx:count"]);
        totalTalking = totalTalking + parseInt(res[date]["pbx:talking"]);
       }
      }
     });
    });
    dataType === "waiting"
     ? this.dataPbxSeries.push(objSeriesWaiting)
     : this.dataPbxSeries.push(objSeriesTalking);
    this.dataPbx["data"] = { series: this.dataPbxSeries, labels: this.dataPbxLabels };
    this.dataPbx["name"] = "PBX";
    this.dataPbx["total"] =
     dataType === "waiting" ? (totalWaiting / total).toFixed(0) : (totalTalking / total).toFixed(0);
   }
   this.$emit("setLoading", false);
  },
  setElementsCharts(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];

   this.dataElementsDuration = [];
   this.extensions.length = 0;

   let arrayOfElements = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   let data = this.data ? this.data : data;

   keys = Object.keys(data);
   if (keys.length > 0) {
    if (this.pbxElements && this.pbxElements.length > 0) {
     this.pbxElements.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     for (let index = 0; index < arrayOfElements.length; index++) {
      let dataElements = {};
      let dataElementsSeries = [];
      let dataElementsLabels = [];
      let total = 0;
      let totalWaiting = 0;
      let totalTalking = 0;
      const element = arrayOfElements[index];
      

      let objSeriesWaiting = {};
      let objSeriesTalking = {};
      objSeriesWaiting["data"] = [];
      objSeriesTalking["data"] = [];
      keys.forEach((date, index) => {
       dataElementsLabels.push(date);
       let objKeys = Object.keys(data[date]);
       objSeriesWaiting["data"][index] ? "" : objSeriesWaiting["data"].splice(index, 0, 0);
       objSeriesTalking["data"][index] ? "" : objSeriesTalking["data"].splice(index, 0, 0);
       objKeys.forEach((key) => {
        let splitKey = key.split(":");
        if (splitKey[0] === this.urlType) {
         if (splitKey[2] === dataType && dataType === "waiting") {
          objSeriesWaiting["name"] = "waiting";
          if (splitKey[1] === element) {
           let value =
            parseInt(data[date][`${this.urlType}:${element}:waiting`]) /
            parseInt(data[date][`${this.urlType}:${element}:count`]);
           objSeriesWaiting["data"].splice(index, 1, value.toFixed(0));
           total = total + parseInt(data[date][`${this.urlType}:${element}:count`]);
           totalWaiting = totalWaiting + parseInt(data[date][`${this.urlType}:${element}:waiting`]);
          }
         }
         if (splitKey[2] === dataType && dataType === "talking") {
          objSeriesTalking["name"] = "talking";
          if (splitKey[1] === element) {
           let value =
            parseInt(data[date][`${this.urlType}:${element}:talking`]) /
            parseInt(data[date][`${this.urlType}:${element}:count`]);
           objSeriesTalking["data"].splice(index, 1, value.toFixed(0));
           total = total + parseInt(data[date][`${this.urlType}:${element}:count`]);
           totalTalking = totalTalking + parseInt(data[date][`${this.urlType}:${element}:talking`]);
          }
         }
        }
       });
      });
      dataType === "waiting"
       ? dataElementsSeries.push(objSeriesWaiting)
       : dataElementsSeries.push(objSeriesTalking);
      dataElements["data"] = { series: dataElementsSeries, labels: dataElementsLabels };
      dataElements["name"] = element;
      
      dataElements["total"] =
       dataType === "waiting"
        ? (totalWaiting / total).toFixed(0)
        : (totalTalking / total).toFixed(0);
      this.dataElementsDuration.push(dataElements);
      
      this.extensions.splice(
       index,
       1,
       arrayOfElements[index] + "-" + this.pbxMap["call_queues"][arrayOfElements[index]]["name"]
      );
     }
    }
   }
   this.$emit("setLoading", false);
  },
  setElementLevelCharts(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];
   this.elementLevelData = {};
   this.elementLevelData["series"] = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   keys = Object.keys(res);

   if (keys.length > 0) {
    // this.dataPbxLabels = [];
    let objSeriesQueueLvl = {};
    objSeriesQueueLvl["series"] = [];
    objSeriesQueueLvl["labels"] = [];
    let total = 0;
    let totalWaiting = 0;
    let totalTalking = 0;
    
    if (this.pbxElements && this.pbxElements.length > 0) {
     keys.forEach((date, index) => {
      //  this.dataPbxLabels.push(date);
      let objKeys = Object.keys(res[date]);
      //  objSeriesWaiting["data"][index] ? "" : objSeriesWaiting["data"].splice(index, 0, 0);
      //  objSeriesTalking["data"][index] ? "" : objSeriesTalking["data"].splice(index, 0, 0);
      objKeys.forEach((key, keyIndex) => {
       let splitKey = key.split(":");
       let queueNumber = splitKey[1];
       if (splitKey[0] === this.urlType) {
        if (splitKey[2] === dataType && this.pbxElements.indexOf(queueNumber) >= 0) {
         if (objSeriesQueueLvl["labels"].indexOf(queueNumber) < 0) {
          objSeriesQueueLvl["labels"].splice(keyIndex, 0, queueNumber);
          objSeriesQueueLvl["series"].splice(keyIndex, 0, {
           data: parseInt(res[date][`${this.urlType}:${queueNumber}:${dataType}`]),
           count: parseInt(res[date][`${this.urlType}:${queueNumber}:count`]),
          });
         } else {
          let indexOf = objSeriesQueueLvl["labels"].indexOf(queueNumber);
          if (
           objSeriesQueueLvl["series"][indexOf] &&
           objSeriesQueueLvl["series"][indexOf]["data"]
          ) {
           objSeriesQueueLvl["labels"].splice(indexOf, 1, queueNumber);
           objSeriesQueueLvl["series"][indexOf]["data"] =
            parseInt(objSeriesQueueLvl["series"][indexOf]["data"]) +
            parseInt(res[date][`${this.urlType}:${queueNumber}:${dataType}`]);
           objSeriesQueueLvl["series"][indexOf]["count"] =
            parseInt(objSeriesQueueLvl["series"][indexOf]["count"]) +
            parseInt(res[date][`${this.urlType}:${queueNumber}:count`]);
          }
         }
        }
       }
      });
     });
     let series = [];
     
     objSeriesQueueLvl["series"].forEach((element, index) => {
      let avg = (element.data / element.count).toFixed(0);
      series.splice(index, 0, avg);
     });
     this.elementLevelData["series"].push({ data: series, name: dataType });
     this.elementLevelData["labels"] = objSeriesQueueLvl["labels"];
     // this.extensions = objSeriesQueueLvl["labels"];
    }

    
   }
   this.$emit("setLoading", false);
  },
  setElementLevelChartsWaitingRange(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];
   this.elementLevelDataRange = {};
   this.elementLevelDataRange["series"] = [];
   this.elementLevelDataRange["labels"] = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   keys = Object.keys(res);

   if (keys.length > 0) {
    // this.dataPbxLabels = [];
    let objSeriesQueueLvl = {};
    objSeriesQueueLvl["series"] = [];
    objSeriesQueueLvl["labels"] = [];
    let series = [];
    keys.forEach((date, index) => {
     let objKeys = Object.keys(res[date]);
     objKeys.forEach((key, keyIndex) => {
      let splitKey = key.split(":");
      let queueNumber = splitKey[1];
      if (this.pbxElements && this.pbxElements.length > 0) {
       if (splitKey[0] === this.urlType && this.pbxElements.indexOf(queueNumber) >= 0) {
        let timeRange = splitKey[2].split("_")[2];
        if (timeRange == this.waitingTimeRange) {
         if (objSeriesQueueLvl["labels"].indexOf(queueNumber) < 0) {
          objSeriesQueueLvl["labels"].splice(keyIndex, 0, queueNumber);
          objSeriesQueueLvl["series"].splice(keyIndex, 0, {
           data: parseInt(res[date][`${this.urlType}:${queueNumber}:${splitKey[2]}`]),
           count: parseInt(res[date][`${this.urlType}:${queueNumber}:count`]),
          });
         } else {
          let indexOf = objSeriesQueueLvl["labels"].indexOf(queueNumber);
          if (
           objSeriesQueueLvl["series"][indexOf] &&
           objSeriesQueueLvl["series"][indexOf]["data"]
          ) {
           objSeriesQueueLvl["labels"].splice(indexOf, 1, queueNumber);
           objSeriesQueueLvl["series"][indexOf]["data"] =
            objSeriesQueueLvl["series"][indexOf]["data"] +
            parseInt(res[date][`${this.urlType}:${queueNumber}:${splitKey[2]}`]);
           objSeriesQueueLvl["series"][indexOf]["count"] =
            objSeriesQueueLvl["series"][indexOf]["count"] +
            parseInt(res[date][`${this.urlType}:${queueNumber}:count`]);
          }
         }
        }
       }
      } else {
       if (splitKey[0] === this.urlType) {
        let timeRange = splitKey[2].split("_")[2];
        if (timeRange == this.waitingTimeRange) {
         if (objSeriesQueueLvl["labels"].indexOf(queueNumber) < 0) {
          objSeriesQueueLvl["labels"].splice(keyIndex, 0, queueNumber);
          objSeriesQueueLvl["series"].splice(keyIndex, 0, {
           data: parseInt(res[date][`${this.urlType}:${queueNumber}:${splitKey[2]}`]),
           count: parseInt(res[date][`${this.urlType}:${queueNumber}:count`]),
          });
         } else {
          let indexOf = objSeriesQueueLvl["labels"].indexOf(queueNumber);
          if (
           objSeriesQueueLvl["series"][indexOf] &&
           objSeriesQueueLvl["series"][indexOf]["data"]
          ) {
           objSeriesQueueLvl["labels"].splice(indexOf, 1, queueNumber);
           objSeriesQueueLvl["series"][indexOf]["data"] =
            objSeriesQueueLvl["series"][indexOf]["data"] +
            parseInt(res[date][`${this.urlType}:${queueNumber}:${splitKey[2]}`]);
           objSeriesQueueLvl["series"][indexOf]["count"] =
            objSeriesQueueLvl["series"][indexOf]["count"] +
            parseInt(res[date][`${this.urlType}:${queueNumber}:count`]);
          }
         }
        }
       }
      }
     });
    });
    objSeriesQueueLvl["series"].forEach((element, index) => {
     let avg = ((element.data / element.count) * 100).toFixed(0);
     series.splice(index, 0, avg);
    });

    objSeriesQueueLvl["labels"].forEach((element, index) => {
     let labelAgent =
      this.pbxMap &&
      this.pbxMap["call_queues"] &&
      this.pbxMap["call_queues"][element] &&
      this.pbxMap["call_queues"][element]["name"]
       ? element + "-" + this.pbxMap["call_queues"][element]["name"]
       : element + "-" + "queue";
     objSeriesQueueLvl["labels"].splice(index, 1, labelAgent);
    });

    let name = "";
    if (this.waitingTimeRange == 5) {
     name = "less than 5s waiting time";
    } else if (this.waitingTimeRange == 10) {
     name = "less than 10s waiting time";
    } else if (this.waitingTimeRange == 15) {
     name = "less than 15s waiting time";
    } else if (this.waitingTimeRange == 20) {
     name = "less than 20s waiting time";
    } else if (this.waitingTimeRange == 25) {
     name = "less than 25s waiting time";
    } else if (this.waitingTimeRange == 30) {
     name = "less than 30s waiting time";
    }
    ("Waiting time");
    this.elementLevelDataRange["series"].push({ data: series, name: name });
    this.elementLevelDataRange["labels"] = objSeriesQueueLvl["labels"];
    // this.extensions = objSeriesQueueLvl["labels"];

    
   }
   this.$emit("setLoading", false);
  },
  setPbxChartsDay(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   keys = Object.keys(res);

   if (keys.length > 0) {
    let labels = [];
    this.dataPbxDay = {};
    let dataPbxSeriesDay = [];
    let objSeries = {};
    objSeries["data"] = [];
    let total = 0;
    let totalWaiting = 0;
    let totalTalking = 0;
    keys.forEach((date) => {
     let objKeys = Object.keys(res[date]);
     objKeys.forEach((key, index) => {
      let splitKey = key.split(":");
      if (splitKey[0] === "pbx") {
       if (splitKey[1] === "waiting") {
        labels.splice(index, 1, "waiting");
        total = total + parseInt(res[date]["pbx:count"]);
        totalWaiting = totalWaiting + parseInt(res[date]["pbx:waiting"]);
        let value = parseInt(res[date]["pbx:waiting"]) / parseInt(res[date]["pbx:count"]);
        objSeries["data"].splice(index, 1, value.toFixed(0));
       } else if (splitKey[1] === "talking") {
        labels.splice(index, 1, "talking");
        let value = parseInt(res[date]["pbx:talking"]) / parseInt(res[date]["pbx:count"]);
        objSeries["data"].splice(index, 1, value.toFixed(0));
        total = total + parseInt(res[date]["pbx:count"]);
        totalTalking = totalTalking + parseInt(res[date]["pbx:talking"]);
       }
      }
     });
    });
    // dataPbxSeriesDay.push(objSeries);
    this.dataPbxDay["data"] = { series: objSeries, labels: labels };
    this.dataPbxDay["name"] = "PBX";
    // this.dataPbxDay["total"] =
    //  dataType === "waiting" ? (totalWaiting / total).toFixed(0) : (totalTalking / total).toFixed(0);
    console.log(
     "file: ExpertStatisticsAnswerTime.vue:368 ~ setPbxChartsDay ~ this.dataPbxDay",
     this.dataPbxDay
    );
   }
   this.$emit("setLoading", false);
  },
  setElementsChartsDay(res) {
   let obj = {};
   let keys = [];
   obj["data"] = [];

   this.dataElementsDurationDay = [];

   let arrayOfElements = [];

   let dataType = !this.showWaitingDuration ? "talking" : "waiting";

   let data = this.data ? this.data : data;

   keys = Object.keys(data);
   if (keys.length > 0) {
    if (this.pbxElements && this.pbxElements.length > 0) {
     this.pbxElements.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     for (let index = 0; index < arrayOfElements.length; index++) {
      let dataElements = {};
      let dataElementsSeries = [];
      let dataElementsLabels = [];
      let total = 0;
      let totalWaiting = 0;
      let totalTalking = 0;
      const element = arrayOfElements[index];
      console.log(
       "file: ExpertStatisticsAnswerTime.vue:240 ~ setElementsCharts ~ element",
       element
      );

      let objSeriesWaiting = {};
      let objSeriesTalking = {};
      objSeriesWaiting["data"] = [];
      objSeriesTalking["data"] = [];
      keys.forEach((date, index) => {
       dataElementsLabels.push(date);
       let objKeys = Object.keys(data[date]);
       objSeriesWaiting["data"][index] ? "" : objSeriesWaiting["data"].splice(index, 0, 0);
       objSeriesTalking["data"][index] ? "" : objSeriesTalking["data"].splice(index, 0, 0);
       objKeys.forEach((key) => {
        let splitKey = key.split(":");
        if (splitKey[0] === this.urlType) {
         if (splitKey[2] === dataType && dataType === "waiting") {
          objSeriesWaiting["name"] = "waiting";
          if (splitKey[1] === element) {
           let value =
            parseInt(data[date][`${this.urlType}:${element}:waiting`]) /
            parseInt(data[date][`${this.urlType}:${element}:count`]);
           objSeriesWaiting["data"].splice(index, 1, value.toFixed(0));
           total = total + parseInt(data[date][`${this.urlType}:${element}:count`]);
           totalWaiting = totalWaiting + parseInt(data[date][`${this.urlType}:${element}:waiting`]);
          }
         }
         if (splitKey[2] === dataType && dataType === "talking") {
          objSeriesTalking["name"] = "talking";
          if (splitKey[1] === element) {
           let value =
            parseInt(data[date][`${this.urlType}:${element}:talking`]) /
            parseInt(data[date][`${this.urlType}:${element}:count`]);
           objSeriesTalking["data"].splice(index, 1, value.toFixed(0));
           total = total + parseInt(data[date][`${this.urlType}:${element}:count`]);
           totalTalking = totalTalking + parseInt(data[date][`${this.urlType}:${element}:talking`]);
          }
         }
        }
       });
      });
      dataType === "waiting"
       ? dataElementsSeries.push(objSeriesWaiting)
       : dataElementsSeries.push(objSeriesTalking);
      dataElements["data"] = { series: dataElementsSeries, labels: dataElementsLabels };
      dataElements["name"] = element;
      dataElements["total"] =
       dataType === "waiting"
        ? (totalWaiting / total).toFixed(0)
        : (totalTalking / total).toFixed(0);
      this.dataElementsDurationDay.push(dataElements);
      console.log(
       "file: ExpertStatisticsAnswerTime.vue:287 ~ setElementsCharts ~ this.dataElementsDurationDay",
       this.dataElementsDurationDay
      );
     }
    }
   }
   this.$emit("setLoading", false);
  },
 },
 watch: {
  urlType: function (value) {
   this.setCharts();
  },
  waitingTimeRange: function (value) {
   this.setCharts();
  },
  periodSelector: function (val) {
   this.setCharts();
  },
  timeSelected: function (val) {
   //    this.getDataFromElement(true);
  },
 },
 mounted() {
  if (!this.periodSelector && !this.pbxPeriodSelected) {
   this.periodSelector = this.currentMonthAndYearForPbx;
  }
  if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.pbxElementType !== this.urlType) this.$store.dispatch("pbxElements", null);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
